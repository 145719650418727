import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { SubmitHandler } from 'react-hook-form'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { useQueryUser } from '../query/useQueryUser'

export type InputFields = {
    firstName: string
    lastName: string
    email: string
    password: string
}

const defaultValues: InputFields = {
    email: '',
    lastName: '',
    firstName: '',
    password: '',
}

export default function RegistrationPage() {
    const { mutationRegisterUser } = useQueryUser()

    const handleSubmit: SubmitHandler<InputFields> = data => {
        mutationRegisterUser.mutate(data)
    }

    return (
        <>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>

            <Box sx={{ mt: 1 }}>
                <FormContainer
                    defaultValues={defaultValues}
                    onSuccess={handleSubmit}
                >
                    <TextFieldElement
                        margin={'normal'}
                        name="firstName"
                        label="First Name"
                        autoComplete="firstName"
                        autoFocus
                        required
                        fullWidth
                    />
                    <TextFieldElement
                        margin={'normal'}
                        name="lastName"
                        label="Last Name"
                        autoComplete="lastName"
                        required
                        fullWidth
                    />
                    <TextFieldElement
                        margin={'normal'}
                        name="email"
                        label="Email"
                        autoComplete="email"
                        required
                        fullWidth
                    />
                    <TextFieldElement
                        margin={'normal'}
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type={'password'}
                        required
                        fullWidth
                    />
                    <Button
                        disabled={mutationRegisterUser.isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                </FormContainer>
            </Box>
        </>
    )
}
