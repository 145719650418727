import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CreateTemplate, create } from '../api/trainingTemplates'

export const trainingTemplatesQueryKey = ['training-templates']

export const useQueryTrainingTemplates = () => {

    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const mutationCreateTrainingTemplate = useMutation(
        (trainingTemplate: CreateTemplate) => create(trainingTemplate),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(trainingTemplatesQueryKey)
                toast.success(t('trainingTemplateNew.create.success'))
            },
            onError: (error: AxiosError) => {
                console.log('error', error)
                toast.error(t('trainingTemplateNew.create.error'))
            },
        },
    )

    return {
        mutationCreateTrainingTemplate
    }
}
