import { AxiosResponse } from 'axios'
import { api } from './index'

export type MovementVariation = {
    id: string
    code: string
    difficulty: number
}

export const list = (): Promise<Array<MovementVariation>> =>
    api
        .get('/movement/variation')
        .then(
            (response: AxiosResponse<Array<MovementVariation>>) => response.data,
        )
