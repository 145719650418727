import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { SubmitHandler } from 'react-hook-form'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { Link as RouterLink } from 'react-router-dom'
import { useQueryUser } from '../query/useQueryUser'

export type InputFields = {
    email: string
    password: string
}

const defaultValues: InputFields = {
    email: '',
    password: '',
}

export default function LoginPage() {
    const { mutationLoginUser } = useQueryUser()

    const handleSubmit: SubmitHandler<InputFields> = data => {
        mutationLoginUser.mutate(data)
    }

    return (
        <>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box sx={{ mt: 1 }}>
                <FormContainer
                    defaultValues={defaultValues}
                    onSuccess={handleSubmit}
                >
                    <TextFieldElement
                        margin={'normal'}
                        name="email"
                        label="Email"
                        autoComplete="email"
                        required
                        fullWidth
                    />
                    <TextFieldElement
                        margin={'normal'}
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type={'password'}
                        required
                        fullWidth
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="/registration"
                                variant="body2"
                            >
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={mutationLoginUser.isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </FormContainer>
            </Box>
        </>
    )
}
