import {useQuery} from '@tanstack/react-query'
import {list, MovementVariation} from '../api/movementVariations'

export const movementVariationQueryKey = ['training-groups']
export const movementVariationMapQueryKey = ['training-groups', 'map']

export const useQueryMovementVariations = () => {

    const movementVariationsMapQuery = useQuery(movementVariationMapQueryKey, () =>
        list().then((movementVariations) => movementVariations.reduce<Record<string, MovementVariation>>((acc, movementVariation: MovementVariation) => {
                acc[movementVariation.id] = movementVariation
                return acc
            }, {}),
        ), {
        enabled: false
    })

    // const queryClient = useQueryClient()
    // const { t } = useTranslation()
    //
    // const mutationCreateGroupTemplate = useMutation(
    //     (trainingTemplate: CreateGroup) => create(trainingTemplate),
    //     {
    //         onSuccess: (data: TrainingGroup) => {
    //             queryClient.invalidateQueries(movementVariationQueryKey)
    //             queryClient.setQueryData(movementVariationMapQueryKey, (old?: Record<string, TrainingGroup>) => {
    //                 if (old) {
    //                     old[data.id] = data
    //                     return old
    //                 }
    //             })
    //             toast.success(t('trainingGroupsNew.create.success'))
    //         },
    //         onError: (error: AxiosError) => {
    //             toast.error(t('trainingGroupsNew.create.error'))
    //         },
    //     },
    // )
    //
    return {
        movementVariationsMapQuery
    }
}
