import { AxiosResponse } from 'axios'
import { api, PaginatedResponse, Pagination } from './index'

export type TrainingGroup = {
    id: string
    name: string
}

export type Statistics = {
    trainingsCount: number
    trainingsDuration: number
    trainingsRounds: number
}

export type CreateGroup = Omit<TrainingGroup, 'id'>

export const list = (
    pagination?: Pagination,
): Promise<PaginatedResponse<TrainingGroup & Statistics>> =>
    api
        .get('/training-group', { params: pagination })
        .then(
            (response: AxiosResponse<{ items: Array<TrainingGroup & Statistics> }>) => ({
                ...response.data,
                totalCount: parseInt(response.headers['x-count'], 10),
            }),
        )

export const create = (
    data: CreateGroup,
): Promise<TrainingGroup> =>
    api
        .post('/training-group',data)
        .then((response: AxiosResponse<TrainingGroup>) => response.data)
