export const trainingsQueryKey = ['trainings']

export const useQueryTrainingGroups = () => {

    // const queryClient = useQueryClient()
    // const { t } = useTranslation()
    //
    // const mutationCreateGroupTemplate = useMutation(
    //     (trainingTemplate: CreateGroup) => create(trainingTemplate),
    //     {
    //         onSuccess: () => {
    //             queryClient.invalidateQueries(trainingGroupsQueryKey)
    //             toast.success(t('trainingGroupsNew.create.success'))
    //         },
    //         onError: (error: AxiosError) => {
    //             toast.error(t('trainingGroupsNew.create.error'))
    //         },
    //     },
    // )
    //
    // return {
    //     mutationCreateGroupTemplate,
    // }
}
