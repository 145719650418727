import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as NavLink } from 'react-router-dom'
import { list } from '../../api/trainingGroups'
import { formatDuration } from '../../modules/timeUtils'
import { trainingGroupsQueryKey } from '../../query/useQueryTrainingGroups'

export default function TrainingGroups() {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const {
        data: { items: groups = [], totalCount = 0 } = {
            items: [],
            totalCount: 0,
        },
    } = useQuery([...trainingGroupsQueryKey, page, rowsPerPage], () =>
        list({ offset: page * rowsPerPage, limit: rowsPerPage }),
    )

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    return (
        <div>
            <Typography
                component='h1'
                variant='h5'
                color='text.primary'
            >
                {t('menu.trainingGroups')}
            </Typography>
            <Link component={NavLink} to={'create'}>
                {t('trainingGroupsNew.title')}
            </Link>
            <Table size='medium'>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>{t('trainingGroup.name')}</TableCell>
                        <TableCell align={'center'}>{t('trainingGroup.trainingCount')}</TableCell>
                        <TableCell align={'center'}>{t('trainingGroup.totalDuration')}</TableCell>
                        <TableCell align={'center'}>{t('trainingGroup.totalRounds')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align={'center'}>{row.name}</TableCell>
                            <TableCell align={'center'}>{row.trainingsCount}</TableCell>
                            <TableCell align={'center'}>{formatDuration(row.trainingsDuration)}</TableCell>
                            <TableCell align={'center'}>{row.trainingsRounds || 0}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}
