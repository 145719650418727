// export const formatDuration = (duration?: number | null) => {
//     if (!duration) return '--:--:--'
//     const hours = Math.floor(duration / 3600)
//     const minutes = Math.floor((duration - hours * 3600) / 60)
//     const seconds = duration - hours * 3600 - minutes * 60
//     return `${hours}:${minutes}:${seconds}`
// }

const padStart = (num: number) => {
    return num.toString().padStart(2, '0')
}

export const formatDuration = (milliseconds?: number | null) => {
    if (!milliseconds) return '--:--:--'
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    // using the modulus operator gets the remainder if the time roles over
    // we don't do this for hours because we want them to rollover
    // seconds = 81 -> minutes = 1, seconds = 21.
    // 60 minutes in an hour, 60 seconds in a minute, 1000 milliseconds in a second.
    minutes = minutes % 60
    seconds = seconds % 60
    // divide the milliseconds by 10 to get the tenths of a second. 543 -> 54
    const ms = Math.floor((milliseconds % 1000) / 10)

    let str = `${padStart(minutes)}:${padStart(seconds)}.${padStart(ms)}`

    if (hours > 0) {
        str = `${padStart(hours)}:${str}`
    }

    return str
}
