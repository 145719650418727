import {AxiosResponse} from 'axios'
import {api} from './index'

export enum UserRole {
    ADMIN = 'Admin',
    USER = 'User',
}

export type User = {
    id: string
    email: string
    firstName: string
    lastName: string
    role: UserRole
}

export type RegisterUser = Omit<User, 'id' | 'role'> & { password: string }

export type Credentials = { email: string; password: string }

export const register = (data: RegisterUser) =>
    api
        .post('/user', data)
        .then((response: AxiosResponse<User>) => response.data)

export const login = (data: Credentials) =>
    api
        .post(
            '/authentication',
            {username: data.email, password: data.password, grant_type: 'password', client_id: 'web'},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        .then((response: AxiosResponse<{ access_token: string, refresh_token: string }>) => {
            localStorage.setItem('accessToken', response.data.access_token)
            localStorage.setItem('refreshToken', response.data.refresh_token)
        })

export const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    return Promise.resolve()
}

export const session = () => api.get('/session').then((response: AxiosResponse<User>) => response.data)

