import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import App from './App'
import AuthLayout from './screen/AuthLayout'
import LoginPage from './screen/LoginPage'
import ProtectedLayout from './screen/ProtectedLayout'
import RegistrationPage from './screen/RegistrationPage'
import TrainingDetail from './screen/Training/TrainingDetail'
import Trainings from './screen/Training/Trainings'
import TrainingGroupNew from './screen/TrainingGroup/TrainingGroupNew'
import TrainingGroups from './screen/TrainingGroup/TrainingGroups'
import TrainingTemplateNew from './screen/TrainingTemplate/TrainingTemplateNew'
import TrainingTemplates from './screen/TrainingTemplate/TrainingTemplates'

export const drawerMenu = [
    { title: 'menu.home', icon: DashboardOutlinedIcon, link: '/' },
    {
        title: 'menu.trainings',
        icon: FitnessCenterOutlinedIcon,
        link: '/trainings',
    },
    {
        title: 'menu.trainingGroups',
        icon: CategoryOutlinedIcon,
        link: '/training-groups',
    },
    {
        title: 'menu.trainingTemplates',
        icon: NoteAddOutlinedIcon,
        link: '/training-templates',
    },
]

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route element={<AuthLayout />}>
                <Route index path='/login' element={<LoginPage />} />
                <Route path='/registration' element={<RegistrationPage />} />
            </Route>
            <Route element={<ProtectedLayout />}>
                <Route index path='/' element={<App />} />
                <Route path='/training-groups'>
                    <Route index element={<TrainingGroups />} />
                    <Route path='create' element={<TrainingGroupNew />} />
                </Route>
                <Route path='/training-templates'>
                    <Route index element={<TrainingTemplates />} />
                    <Route path='create' element={<TrainingTemplateNew />} />
                </Route>
                <Route path='/trainings'>
                    <Route index element={<Trainings />} />
                    <Route path={':id'} element={<TrainingDetail />} />
                    {/*<Route path='create' element={<TrainingTemplateNew />} />*/}
                </Route>
            </Route>
        </Route>,
    ),
)
