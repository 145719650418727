import {Card} from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {useQuery} from '@tanstack/react-query'
import React, {useEffect, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {AutocompleteElement, FormContainer, TextFieldElement} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import {list} from '../../api/trainingGroups'
import {useQueryMovementVariations} from '../../query/useQueryMovementVariations'
import {trainingGroupsQueryKey} from '../../query/useQueryTrainingGroups'
import {useQueryTrainingTemplates} from '../../query/useQueryTrainingTemplates'

type InputFields = {
    name: string
    duration: number | null
    rounds: number | null
    breakBetweenRounds: number
    trainingGroupId: string
    trainingRoundTemplates: Array<{
        trainingRoundMovementTemplates: Array<{repetitions: number, movementVariationId: string}>
    }>
}

const defaultValues = {
    name: '',
    duration: null,
    rounds: null,
    breakBetweenRounds: 0,
    trainingGroupId: ''
}

const TrainingRound = ({position, index}: { position: number, index: number }) => {
    const {t} = useTranslation()
    const [movements, setMovements] = useState<Array<number>>([])

    const {movementVariationsMapQuery} = useQueryMovementVariations()

    const movementVariations = movementVariationsMapQuery.data || {}

    useEffect(() => {
        movementVariationsMapQuery.refetch()
    }, [])

    return <Grid item xs={12}>
        <Card style={{padding: 12}}>
            <Grid item xs={12}>
                <Typography>
                    Round {index + 1}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {movements.map((movement, index) =>
                        <React.Fragment key={index}>
                            <Grid item xs={1}>
                                <TextFieldElement
                                    margin={'normal'}
                                    name={`trainingRoundTemplates[${position}].trainingRoundMovementTemplates[${movement}].repetitions`}
                                    label={t('trainingMovement.repetitions')}
                                    type={'text'}
                                    validation={{min: {value: 0, message: t('form.positive')}}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteElement
                                    label={t('training.trainingGroupName')}
                                    name={`trainingRoundTemplates[${position}].trainingRoundMovementTemplates[${movement}].movementVariationId`}
                                    matchId
                                    textFieldProps={{
                                        margin: 'normal',
                                    }}
                                    required
                                    options={Object.values(movementVariations).map(movementVariation => ({
                                        id: movementVariation.id,
                                        label: t(`movement.${movementVariation.code}`),
                                    }))}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button variant="text" onClick={() => setMovements([
                    ...movements,
                    movements.length ? (movements[movements.length - 1] + 1) : 0
                ])}>Add
                    movement</Button>
            </Grid>
        </Card>
    </Grid>
}

export default function TrainingTemplateNew() {
    const {t} = useTranslation()
    const {mutationCreateTrainingTemplate} = useQueryTrainingTemplates()
    const [rounds, setRounds] = useState<Array<number>>([])
    const handleSubmit: SubmitHandler<InputFields> = data => {
        // console.log('data', data)
        mutationCreateTrainingTemplate.mutate(data)
    }

    const {
        data: {items: groups = []} = {
            items: [],
            totalCount: 0,
        },
    } = useQuery(trainingGroupsQueryKey, () => list())

    return (
        <>
            <Typography
                component="h1"
                variant="h5"
                color="text.primary"
            >
                {t('trainingTemplateNew.title')}
            </Typography>
            <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextFieldElement
                            margin={'normal'}
                            name={'name'}
                            label={t('trainingTemplate.name')}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteElement
                            label={t('training.trainingGroupName')}
                            name="trainingGroupId"
                            matchId
                            textFieldProps={{
                                margin: 'normal',
                            }}
                            required
                            options={groups.map(group => ({
                                id: group.id,
                                label: group.name,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFieldElement
                            margin={'normal'}
                            name="duration"
                            label={t('trainingTemplate.duration')}
                            type={'number'}
                            validation={{min: {value: 0, message: t('form.positiveOrEmpty')}}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFieldElement
                            margin={'normal'}
                            name="rounds"
                            label={t('trainingTemplate.rounds')}
                            type={'number'}
                            validation={{min: {value: 0, message: t('form.positiveOrEmpty')}}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFieldElement
                            margin={'normal'}
                            name="breakBetweenRounds"
                            label={t('trainingTemplate.breakBetweenRounds')}
                            type={'number'}
                            validation={{min: {value: 0, message: t('form.positive')}}}
                            fullWidth
                        />
                    </Grid>
                    {
                        rounds.map((position, index) => (
                            <TrainingRound position={position} key={position} index={index}/>
                        ))
                    }

                    <Grid item xs={12}>
                        <Button variant="text" onClick={() => setRounds([
                            ...rounds,
                            rounds.length ? (rounds[rounds.length - 1] + 1) : 0
                        ])}>Add
                            round</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            disabled={mutationCreateTrainingTemplate.isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            {t('trainingTemplateNew.create.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </>
    )
}
