import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {useNavigate} from 'react-router-dom'
import {AxiosError} from 'axios'
import {
    Credentials,
    login,
    logout,
    register,
    RegisterUser,
    session,
    User,
} from '../api/user'

export const userQueryKey = ['user']
const sessionQueryKey = ['myself']
import {toast} from 'react-toastify'

export const useQueryUser = () => {
    const sessionUser = useQuery(sessionQueryKey, () => session(), {
        enabled: false,
    })
    //
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    // const toast = useToast()
    //
    const mutationLoginUser = useMutation(
        (credentials: Credentials) => login(credentials),
        {
            onSuccess: () => {
                navigate('/')
            },
        }
    )

    const mutationLogoutUser = useMutation(() => logout(), {
        onSuccess: () => {
            queryClient.setQueryData(sessionQueryKey, null)
            navigate('/login')
        },
    })

    const mutationRegisterUser = useMutation(
        (user: RegisterUser) => register(user),
        {
            onSuccess: (data: User) => {
                queryClient.setQueryData(sessionQueryKey, data)
                navigate('/login')
            },
            onError: (error: AxiosError) => {
                toast.error(
                    error.response?.status === 409
                        ? 'User already exists'
                        : error.message
                )
            },
        }
    )

    return {
        sessionUser,
        mutationRegisterUser,
        mutationLoginUser,
        mutationLogoutUser,
        // mutationNewUser,
        // mutationRemoveUser,
        // mutationUpdateUser,
    }
}
