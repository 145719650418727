import {
    Breadcrumbs,
    Card,
    CardContent,
    Divider,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid2 from '@mui/material/Unstable_Grid2'
import {useQuery} from '@tanstack/react-query'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink, useParams} from 'react-router-dom'
import {detail} from '../../api/trainings'
import {formatDuration} from '../../modules/timeUtils'
import {useQueryMovementVariations} from '../../query/useQueryMovementVariations'
import {trainingsQueryKey} from '../../query/useQueryTrainings'

export default function TrainingDetail() {
    const routeParams = useParams<{ id: string }>()
    const { t } = useTranslation()

    const {movementVariationsMapQuery} = useQueryMovementVariations()

    const movementVariations = movementVariationsMapQuery.data || {}

    useEffect(() => {
        movementVariationsMapQuery.refetch()
    }, [])

    const { data: training } = useQuery([...trainingsQueryKey, routeParams.id], () => detail(routeParams.id as string))

    const movementStatistics = useMemo(() => {
        const statistics: Record<string, number> = {}
        for (const round of training?.trainingRounds || []) {
            for (const movement of round.trainingRoundMovements) {
                const key = movement.movementVariationId
                if (statistics[key]) {
                    statistics[key] += movement.repetitions
                } else {
                    statistics[key] = movement.repetitions
                }
            }
        }
        return statistics
    }, [training?.trainingRounds])

    return (
        <>
            <Breadcrumbs
                separator='›'
                aria-label='breadcrumb'
                sx={{ mb: 3 }}
            >
                <Link
                    component={RouterLink}
                    underline='hover'
                    color='inherit'
                    to={'/trainings'}
                >
                    {t('menu.trainings')}
                </Link>

                <Typography
                    component='h1'
                    variant='h5'
                    color='text.primary'
                >
                    {training?.name}
                </Typography>
            </Breadcrumbs>

            <Grid2 container spacing={2}>
                <Grid2 xs={6} md={6}>
                    <Card>
                        <CardContent>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={'center'}>{t('trainingRound.position')}</TableCell>
                                        <TableCell align={'center'}>{t('trainingRound.duration')}</TableCell>
                                        {
                                            [...Array(training?.movementsPerRound)].map((_, index) => (
                                                <TableCell key={index} align={'center'}>{index + 1}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {training?.trainingRounds
                                        .sort((a, b) => a.position - b.position)
                                        .map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell align={'center'}>{row.position}</TableCell>
                                                <TableCell align={'center'}>{formatDuration(row.duration)}</TableCell>
                                                {row.trainingRoundMovements
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((movement) => (
                                                        <TableCell key={movement.id}
                                                                   align={'center'}>{movement.repetitions}x {t(`movement.${movementVariations[movement.movementVariationId]?.code}`)}</TableCell>
                                                    ))}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 xs={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant='h4' component='div'>
                                {t('training.totalDuration')}: {formatDuration(training?.duration)}
                            </Typography>
                            <Typography variant='h5' component='div'>
                                {t('training.totalRounds')}: {training?.rounds}
                            </Typography>
                            <Divider />
                            {
                                Object.entries(movementStatistics).map(([key, value]) => (
                                    <Typography key={key} variant='h6' component='div'>
                                        {t(`movement.${movementVariations[key]?.code}`)}: {value}
                                    </Typography>
                                ))
                            }
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </>
    )
}
