import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import {SubmitHandler} from 'react-hook-form'
import {FormContainer, TextFieldElement} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import {useQueryTrainingGroups} from '../../query/useQueryTrainingGroups'

type InputFields = {
    name: string
}

const defaultValues = {
    name: '',
}

export default function TrainingGroupNew() {
    const { t } = useTranslation()
    const { mutationCreateGroupTemplate } = useQueryTrainingGroups()

    const handleSubmit: SubmitHandler<InputFields> = data => {
        mutationCreateGroupTemplate.mutate(data)
    }

    return (
        <>
            <Typography
                component='h1'
                variant='h5'
                color='text.primary'
            >
                {t('trainingGroupsNew.title')}
            </Typography>
            <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldElement
                            margin={'normal'}
                            name={'name'}
                            label={t('trainingGroup.name')}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={mutationCreateGroupTemplate.isLoading}
                            type='submit'
                            fullWidth
                            variant='contained'
                        >
                            {t('trainingGroupsNew.create.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </>
    )
}
