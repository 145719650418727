import axios, {AxiosError, InternalAxiosRequestConfig} from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

export type Pagination = {
    limit: number | null
    offset: number | null
}

export type PaginatedResponse<T> = {
    items: Array<T>
    totalCount: number
}

export const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('accessToken')

        if (token) {
            config.headers.set('Authorization', `Bearer ${token}`)
        }

        return config
    },
    (error) => Promise.reject(error)
)

api.interceptors.response.use(
    response => {
        return response
    },
    async (error: AxiosError<{ error?: string }>) => {

        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean }
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            // await refreshAccessTokenFn()
            const refreshToken = localStorage.getItem('refreshToken')
            if (!refreshToken) {
                document.location.href = '/login'
                return
            }
            return api(originalRequest)
        } else if (error.response?.status === 401) {
            document.location.href = '/login'
        }

        return Promise.reject(error)
    }
)
//
// api.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         const errMessage = error.response.data.message as string;
//         if (errMessage.includes('not logged in') && !originalRequest._retry) {
//             originalRequest._retry = true;
//             await refreshAccessTokenFn();
//             return authApi(originalRequest);
//         }
//         return Promise.reject(error);
//     }
// );