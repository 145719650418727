import { AxiosResponse } from 'axios'
import { api, PaginatedResponse, Pagination } from './index'
import { TrainingGroup } from './trainingGroups'

export type Training = {
    id: string
    name: string
    performedAt: string,
    duration: number
    rounds: number
    movementsPerRound: number
    trainingGroupId: string
}

export type TrainingRound = {
    id: string
    duration: number
    position: number
}

export type TrainingRoundMovement = {
    id: string
    repetitions: number
    position: number
    movementVariationId: string
}

export type CreateTraining = Omit<Training, 'id'>

export type TrainingDetail = Training & {trainingRounds: Array<TrainingRound & {trainingRoundMovements: Array<TrainingRoundMovement>}>, trainingGroup: TrainingGroup}

export const list = (
    pagination: Pagination,
): Promise<PaginatedResponse<Training>> =>
    api
        .get('/training', { params: pagination })
        .then(
            (response: AxiosResponse<{ items: Array<Training> }>) =>
                ({
                    ...response.data,
                    totalCount: parseInt(response.headers['x-count'], 10),
                }),
        )

export const detail = (
    id: string,
): Promise<TrainingDetail> =>
    api
        .get(`/training/${id}`)
        .then((response: AxiosResponse<TrainingDetail>) => response.data)

export const create = (
    data: CreateTraining,
): Promise<Training> =>
    api
        .post('/training', data)
        .then((response: AxiosResponse<Training>) => response.data)
