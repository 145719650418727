import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { create, CreateGroup, TrainingGroup } from '../api/trainingGroups'

export const trainingGroupsQueryKey = ['training-groups']
export const trainingGroupsMapQueryKey = ['training-groups', 'map']

export const useQueryTrainingGroups = () => {

    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const mutationCreateGroupTemplate = useMutation(
        (trainingTemplate: CreateGroup) => create(trainingTemplate),
        {
            onSuccess: (data: TrainingGroup) => {
                queryClient.invalidateQueries(trainingGroupsQueryKey)
                queryClient.setQueryData(trainingGroupsMapQueryKey, (old?: Record<string, TrainingGroup>) => {
                    if (old) {
                        old[data.id] = data
                        return old
                    }
                })
                toast.success(t('trainingGroupsNew.create.success'))
            },
            onError: (error: AxiosError) => {
                console.log('error', error)
                toast.error(t('trainingGroupsNew.create.error'))
            },
        },
    )

    return {
        mutationCreateGroupTemplate,
    }
}
