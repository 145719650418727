import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { list as groupList, TrainingGroup } from '../../api/trainingGroups'
import { list } from '../../api/trainings'
import { formatDuration } from '../../modules/timeUtils'
import { trainingGroupsMapQueryKey } from '../../query/useQueryTrainingGroups'
import { trainingsQueryKey } from '../../query/useQueryTrainings'

export default function Trainings() {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const navigate = useNavigate()

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const {
        data: { items: trainings = [], totalCount = 0 } = {
            items: [],
            totalCount: 0,
        },
    } = useQuery([...trainingsQueryKey, page, rowsPerPage], () =>
        list({ offset: page * rowsPerPage, limit: rowsPerPage }),
    )

    const { data: groups = {} } = useQuery(trainingGroupsMapQueryKey, () =>
        groupList().then((groups) => groups.items.reduce<Record<string, TrainingGroup>>((acc, group: TrainingGroup) => {
                acc[group.id] = group
                return acc
            }, {}),
        ))

    return (
        <>
            <Typography
                component="h1"
                variant="h5"
                color="text.primary"
            >
                {t('menu.trainings')}
            </Typography>
            <Table size='medium'>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>{t('training.name')}</TableCell>
                        <TableCell align={'center'}>{t('training.trainingGroupName')}</TableCell>
                        <TableCell align={'center'}>{t('training.duration')}</TableCell>
                        <TableCell align={'center'}>{t('training.rounds')}</TableCell>
                        <TableCell align={'center'}>{t('training.performedAt')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {trainings.map((row) => (
                        <TableRow key={row.id} onClick={() => {navigate(row.id)}} style={{cursor: 'pointer'}}>
                            <TableCell align={'center'}>{row.name}</TableCell>
                            <TableCell align={'center'}>{groups[row.trainingGroupId]?.name}</TableCell>
                            <TableCell align={'center'}>{formatDuration(row.duration)}</TableCell>
                            <TableCell align={'center'}>{row.rounds}</TableCell>
                            <TableCell
                                align={'center'}>{format(new Date(row.performedAt), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
