import { AxiosResponse } from 'axios'
import { api, PaginatedResponse, Pagination } from './index'

export type TrainingTemplate = {
    id: string
    name: string
    duration: number | null
    rounds: number | null
    breakBetweenRounds: number
    trainingGroupId: string
}

export type CreateTemplate = Omit<TrainingTemplate, 'id'>

export const list = (
    pagination?: Pagination,
): Promise<PaginatedResponse<TrainingTemplate>> =>
    api
        .get('/training-template', { params: pagination })
        .then(
            (response: AxiosResponse<{ items: Array<TrainingTemplate> }>) => ({
                ...response.data,
                totalCount: parseInt(response.headers['x-count'], 10),
            }),
        )

export const create = (
    data: CreateTemplate,
): Promise<TrainingTemplate> =>
    api
        .post('/training-template', {
            ...data,
            duration: data.duration ? data.duration * 60 * 1000 : null
        })
        .then((response: AxiosResponse<TrainingTemplate>) => response.data)
