import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from '@mui/material'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import {useQuery} from '@tanstack/react-query'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link as NavLink} from 'react-router-dom'
import {list as groupList, TrainingGroup} from '../../api/trainingGroups'
import {list} from '../../api/trainingTemplates'
import {formatDuration} from '../../modules/timeUtils'
import {trainingGroupsMapQueryKey} from '../../query/useQueryTrainingGroups'
import {trainingTemplatesQueryKey} from '../../query/useQueryTrainingTemplates'

export default function TrainingTemplates() {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const {t} = useTranslation()
    const {
        data: {items: templates = [], totalCount = 0} = {
            items: [],
            totalCount: 0,
        },
    } = useQuery([...trainingTemplatesQueryKey, page, rowsPerPage], () =>
        list({offset: page * rowsPerPage, limit: rowsPerPage})
    )

    const { data: groups = {} } = useQuery(trainingGroupsMapQueryKey, () =>
        groupList().then((groups) => groups.items.reduce<Record<string, TrainingGroup>>((acc, group: TrainingGroup) => {
                acc[group.id] = group
                return acc
            }, {}),
        ))

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <div>
            <Typography
                component="h1"
                variant="h5"
                color="text.primary"
            >
                {t('menu.trainingTemplates')}
            </Typography>
            <Link component={NavLink} to={'create'}>
                Add new
            </Link>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>{t('trainingTemplate.name')}</TableCell>
                        <TableCell align={'center'}>{t('training.trainingGroupName')}</TableCell>
                        <TableCell align={'center'}>{t('trainingTemplate.duration')}</TableCell>
                        <TableCell align={'center'}>{t('trainingTemplate.rounds')}</TableCell>
                        <TableCell align={'center'}>{t('trainingTemplate.breakBetweenRounds')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templates.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align={'center'}>{row.name}</TableCell>
                            <TableCell align={'center'}>{groups[row.trainingGroupId]?.name}</TableCell>
                            <TableCell align={'center'}>{formatDuration(row.duration)}</TableCell>
                            <TableCell align={'center'}>{row.rounds || 0}</TableCell>
                            <TableCell align={'center'}>{row.breakBetweenRounds || 0}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}
