import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Outlet } from 'react-router-dom'

export default function AuthLayout() {
    return (
        <Container component="main" maxWidth="sm">
            <Typography
                mt={8}
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Calisthenics
            </Typography>
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 4,
                    py: 6,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Outlet />
            </Box>
        </Container>
    )
}
